import React from 'react'
import Youtube from 'react-youtube'
import './index.scss'

const defaultOpts = {
  height: '360',
  width: '640',
  playerVars: {
    autoplay: 0,
    modestbranding: 1,
    rel: 0,
  }
}

const VideoPlayerYoutube = ({videoId, opts}) => {
  const playerOpts = {...defaultOpts, ...opts} 

  return (
    <Youtube 
      videoId={videoId}
      opts={playerOpts}
      containerClassName="react-youtube"
    />
  )
}

export default VideoPlayerYoutube
