// const dotenv = require('dotenv')

// if (process.env.NODE_ENV !== 'production') {
//   dotenv.config({
//     path: '.env'
//   })
// }

// let hostName = `cdn.contentful.com`;

// if (process.env.CONTENTFUL_API_TYPE === 'preview') {
//   hostName = `preview.contentful.com`
// } 

const languages = require('./src/data/languages');

module.exports = {
  siteMetadata: {
    siteUrl: `https://delight.global`,
    title: 'Smart home and voice assistant solution - Delight',
    description: "Delight's voice butler is perfectly incorporated in Google Home, Amazon Alexa and Apple Siri. Choose from curated products that fit your lifestyle and we handle the rest.",
    languages
  },
  // pathPrefix: `/gatsby`,
  plugins: [
    `gatsby-plugin-sass`,
    `gatsby-plugin-less`,
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Delight',
        short_name: 'Delight',
        start_url: '/',
        background_color: '#74c5fd',
        theme_color: '#74c5fd',
        display: 'minimal-ui',
        icon: 'src/assets/img/logo/delight-logo-sq.svg', // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-129654504-1",
        head: true,
      },
    },
    // only compatiable to gatsby 3
    // {
    //   resolve: `gatsby-plugin-google-gtag`,
    //   options: {
    //     trackingIds: [
    //       "UA-129654504-1", // old id as of 20210330
    //       "G-4TLH5RM2L5" // new id as of 20210330
    //     ],
    //     pluginConfig: {
    //       // Puts tracking script in the head instead of the body
    //       head: true,
    //     },
    //   }
    // },
    `gatsby-plugin-remove-serviceworker`,
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        // output: `/some-other-sitemap.xml`,
        // Exclude specific pages or groups of pages using glob parameters
        // See: https://github.com/isaacs/minimatch
        // The example below will exclude the single `path/to/page` and all routes beginning with `category`
        exclude: ["/ihome"]
      }
    },
    {
      resolve: 'gatsby-plugin-i18n',
      options: {
        langKeyForNull: 'any',
        langKeyDefault: languages.defaultLangKey,
        useLangKeyLayout: true,
        prefixDefault: false,
      }
    }
    // {
    //   resolve: `gatsby-source-contentful`,
    //   options: {
    //     spaceId: `3232tjnm2sxw`,

    //     // Learn about environment variables: https://gatsby.app/env-vars
    //     // the accessToken depends on the process.env.CONTENTFUL_PREVIEW, 
    //     // 'production' or not 'preview' --> Content Delivery API - access token
    //     // 'preview' -> Content Preview API - access token
    //     accessToken: process.env.CONTENTFUL_API_ACCESS_TOKEN, // the dotenv seems to have bug
    //     // accessToken: '-57xmrQJC5KNCwy85qwFk4m-jfAjDATE8LQAFTOv6kI',
    //     host: hostName, // depends on the CONTENTFUL_API_TYPE in netifly env var
    //     downloadLocal: true,
    //   },
    // },
  ],
}
