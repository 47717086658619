// // use this for the time being because chinese translation is not ready yet
// module.exports = {
//   langs: ['en'],
//   defaultLangKey: 'en'
// };

// use this after all chinese pages are ready
module.exports = {
  langs: ['en', 'zh', 'zh-hk'],
  defaultLangKey: 'en'
};